export class MarriageApplicationPojo {
    marriageApplicationId: number;
    groomName: string;
    groomFatherName: string;
    groomAddress: string;
    groomRegion: string;
    groomCaste: string;
    groomDob: Date;
    groomAge: string;
    brideName: string;
    brideFatherName: string;
    brideAddress: string;
    brideRegion: string;
    brideCaste: string;
    brideProofNumber: string;
    brideDob: Date;
    brideAge: string;
    groomAlreadyMarried: string;
    brideAlreadyMarried: string;
    groomProofNumber: string;
    mobileNo: string;
    marriageDate: Date;
    marriageTime: string;
    groomFtpId: number;
    brideFtpId: number;
    registerDate: Date;
    bookingDate: Date;
    bookingTime: string;
    marriageTypeId: number;
    slotAssignId: number;
    noOfBooking: number;
    transactionId: number;
    status: string;
    receiptId: number;
    marriageRegionId: number;
    proofNumber: string;
    devoteeUserId: number;
    amount: number;
    emailId: string;
    noOfDays: number;
    marriageTypeName:string; 
    groomProofFile:string;
    brideProofFile:string;
}
